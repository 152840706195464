import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="admin--spi-payment-statuses"
export default class extends Controller {
  connect() {
    console.log('connect spi status')
    $('.spi_payment_member_id').select2({
      minimumInputLength: 3,
      placeholder: "All",
      allowClear: true
      // multiple: true
    });

    $('#loading_data').hide()

    $('.right-arrow').click(function(){
      $('.spi_report_table').animate( { scrollLeft: '+=100' }, 100);
    })
    $('.left-arrow').click(function(){
      $('.spi_report_table').animate( { scrollLeft: '-=100' }, 100);
    })

    if($('#payment_status_filter_worked').is(':checked')){
      $('#payment_status').parent().parent().parent().show()
      $('input[type="checkbox"]').parent().parent().parent().parent().show()
      $('#member_id').parent().parent().parent().show()
      $('#payment_date').parent().parent().parent().show()
    }
  }

  collectionChecked(e){
    var element = event.currentTarget
    if(element.checked){
      $('#member_id').parent().parent().parent().show()
      $('input[type="checkbox"]').parent().parent().parent().parent().hide()
      $('#payment_status').parent().parent().parent().hide()
      $('#payment_date').parent().parent().parent().hide()
    }
  }

  inprocessChecked(e){
    $('#payment_status').parent().parent().parent().show()
    $('input[type="checkbox"]').parent().parent().parent().parent().show()
    $('#member_id').parent().parent().parent().show()
    $('#payment_date').parent().parent().parent().show()
  }

  clearedChecked(e){
    console.log('clicked')
    var element = event.currentTarget
    if(element.checked){
      $('input[type="checkbox"]').parent().parent().parent().parent().show()
      $('#member_id').parent().parent().parent().show()
      $('#payment_status').parent().parent().parent().hide()
      $('#payment_date').parent().parent().parent().hide()
    }
  }

  submitForm(){
    if($('#spi-payment-statuses').valid())
      $('#loading_data').show()
  }
}
