import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="applicants--igw-bank-detail"
export default class extends Controller {
  connect() {
   console.log('connect Controller')
    validator()

    $('.bank_detail_section').mouseover(function(){
      BankDetailvalidationChecker()
    })

    $('#igw_bank_detail_edit_form input').keyup(function(){
      BankDetailvalidationChecker()
    });

    $('#igw_bank_detail_edit_form').validate({
      rules: {
        'applicant[routing_number]': {
          minlength: 9,
          maxlength: 9,
          required: true,
          digits: true,
          allZero: true
        },
        'applicant[account_number]': {
          required: true,
          digits: true,
          minlength: 6,
          maxlength: 25,
          allZero: true
        },
        'applicant[bank_name]':{
          maxlength: 256,
          required: true,
          blankSpace: true,
          alphabetWithSplSym: true
        }
      },
      messages: {
        'applicant[routing_number]': {
          minlength: 'Sorry, routing number must be 9 digits',
          maxlength: 'Sorry, routing number must be 9 digits',
          digits: 'Sorry, only numbers are allowed.',
          allZero: 'Sorry, number is not valid.'

        },
        'applicant[account_number]': {
          maxlength: "Sorry, account number must be between 6 and 25 digits.",
          minlength: "Sorry, account number must be between 6 and 25 digits.",
          digits: 'Sorry, only numbers are allowed.',
          allZero: 'Sorry, number is not valid.'
        },
        'applicant[bank_name]':{
          required: 'Please enter bank name.',
          maxlength: "Sorry, bank name must be less then 256 words.",
          blankSpace: 'Blank space not allowed.',
          alphabetWithSplSym: 'Please enter valid bank name.'
        }
      }
    });

    $.validator.addMethod("blankSpace", function(value, element) {
      if(value.replaceAll(' ', '').length == 0){
        return false
      }else{
        return this.optional(element) || true
      }
    });
  }
}

window.validator = function(){
  console.log('call validator')
  $.validator.addMethod("allZero", function(value, element) {
    return this.optional(element) || !(! + value);
  });

  $.validator.addMethod("alphabetWithSplSym", function(value, element) {
    return this.optional(element) || /^(?=.*[A-Za-z\d]{2,})[A-Za-z\d!@#$%^&*()_+~=`~-]{2,}/.test(value);
  });

  $.validator.addMethod("blankSpace", function(value, element) {
    if(value.replaceAll(' ', '').length == 0){
      return false
    }else{
      return this.optional(element) || true
    }
  });
}

window.BankDetailvalidationChecker = function(){
  console.log('call BankDetailvalidationChecker')
  let inputsWithValues = 0, totalInputs = 0;
  let myInputs         = $('#igw_bank_detail_edit_form input:required');

  myInputs.each(function(e) {
    if ($(this).val()) {
      if($(this).valid()){
        inputsWithValues += 1;
        $(this).prev().prev().css('color', '#3c7643')
      }
      else{
        $(this).prev().prev().css('color', '#a94442')
      }
    }
  });

  if (inputsWithValues == myInputs.length) {
    $(".applicant_submission").prop("disabled", false);
  } else {
    $(".applicant_submission").prop("disabled", true);
  }
}
