import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="applicants--subscriber-details"
export default class extends Controller {
  connect() {
    $('#subscriber_edit_form').validate()
    subscriber_validation();
  }

  modalApplicantDetailedit(event){
   let element = event.currentTarget
    if(element.checked){
      var applicant_name = $('#product-info-igw').data('fullName')
      var address =  $('#product-info-igw').data('address')
      $('.modal_subscriber_name').val(applicant_name)
      $('.modal_subscriber_address').val(address)
    }else{
      $('.modal_subscriber_name').val('')
      $('.modal_subscriber_address').val('')
    } 
  }
}

window.subscriber_validation = function(){
  console.log('subscriber_validation')
  $('#more_detail_subscriber_name').rules('add', {
    required: true,
    alphabetswithspace: true,
    messages: {
      required: 'Please enter subscriber name.',
      alphabetswithspace: 'Please enter only alphabates.'
    }
  });
  
  $('#more_detail_subscriber_address').rules('add', {
    required: true,
    messages: {
      required: 'Please enter subscriber address.'
    }
  });

  $.validator.addMethod("alphabetswithspace", function(value, element) {
    return this.optional(element) || /^[a-zA-Z ]+$/.test(value);
  });

}
