import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="applicants--bank-details"
export default class extends Controller {
  connect() {
    bankDetailValidator()
    applyDatePickerNextPaymentDate()
    $("form").submit(function(){
      $('input[type=submit]').click(function(event){
        event.preventDefault();
      });
    });
    timeout_session('#{encoded_url}')
    $('.bank_pupop_btn').trigger("click");
    $("#bank_detail_v3").validate({
      rules: {
        "applicant[account_card_number]": {
          required: true,
          digits: true,
          accountcardregex: true,
          minlength: 16,
          maxlength: 16
          },
        "applicant[card_exp_month]": {
          required: true,
          minlength: 1,
          maxlength: 2,
          min: 1,
          max: 12
        },
        "applicant[card_exp_year]": {
          required: true,
          digits: true
        },
        "applicant[card_cvv]": {
          required: true,
          digits: true,
          minlength: 3,
          maxlength: 4
        },
        "applicant[bank_aba]": {
          required: true,
          digits: true,
          minlength: 9,
          maxlength: 9
        },
        "applicant[bank_account_number]": {
          required: true,
          digits: true,
          minlength: 5,
          maxlength: 25
        },
        "applicant[next_payment_date]": {
          required: true,
          dateregex: true
        },
        'applicant[payment_frequency]': {
          required: true
        }
      },
      messages: {
        "applicant[account_card_number]": {
          required: "Please enter debit card number.",
          accountcardregex: "Please enter a valid debit card number.",
          digits: "Sorry, only numbers are allowed.",
          minlength: 'Sorry, debit card must be 16 digits.',
          maxlength: 'Sorry, debit card must be 16 digits.'
        },
        "applicant[card_exp_month]": {
          required: 'Please select card expiry month.',
          minlength: '',
          maxlength: '',
          min: 'Sorry, Card expiry month must be between 1 and 12.',
          max: 'Sorry, Card expiry month must be between 1 and 12.'
        },
        "applicant[card_exp_year]": {
          required: 'Please select card expiry year.',
          digits: 'Sorry, only numbers are allowed.'
        },
        "applicant[card_cvv]": {
          required: 'Please enter vaild CVV number.',
          digits: 'Sorry, only numbers are allowed.',
          maxlength: "Sorry, CVV number must be 3 or 4 digits.",
          minlength: "Sorry, CVV number must be 3 or 4 digits."
        },
        "applicant[bank_aba]": {
          required: "Please enter your bank's ABA (routing) number.",
          digits: 'Sorry, only numbers are allowed.',
          maxlength: "Sorry, the ABA number must be 9 characters long.",
          minlength: "Sorry, the ABA number must be 9 characters long."
        },
        "applicant[bank_account_number]": {
          required: 'Please enter bank account number.',
          digits: 'Sorry, only numbers are allowed.',
          maxlength: "Sorry, account number must be between 5 and 25 digits.",
          minlength: "Sorry, account number must be between 5 and 25 digits."
        },
        "applicant[next_payment_date]": {
          required: "Please select next payment date.",
          dateregex: 'Next Payment date must be in mm/dd/yyyy format.'
        },
        'applicant[payment_frequency]': {
          required: 'Please select your pay frequency.'
        }
      }
    });


    submitBtnEnableDisable();

    $('input').keyup(function(){
      submitBtnEnableDisable();
    })

    $('select').change(function(){
      submitBtnEnableDisable();
    })

    $('#bank_detail_v3').on('mouseover', function function_name(argument) {
      submitBtnEnableDisable();
    });

    $('#applicant_payment_frequency').change(function() {
      nextPaymentDateLimit($(this))
    });

    $('#applicant_next_payment_date').keyup(function(){
      addSlash($(this))
    })
  }
}
window.bankDetailValidator = function() {
  $.validator.addMethod("accountcardregex", function(value, element) {
    return this.optional(element) || /^[0-9]*$/.test(value);
  });

  $.validator.addMethod("dateregex", function(value, element) {
    return this.optional(element) || /^\d{2}\/\d{2}\/\d{4}$/.test(value);
  });
}

window.applyDatePickerNextPaymentDate =  function(){
  $('#applicant_next_payment_date').datepicker({
    format: 'mm/dd/yyyy',
    autoclose: true,
    todayHighlight: true,
    startDate: '+1D',
    endDate: '+7D'
  });
}
