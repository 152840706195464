import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="detail-report"
export default class extends Controller {
  connect(){
    $('#member_ids, #lender_ids, #sub_links, #status_ids, #loan_purpose, #account_owner_ids, #state_ids, #promotion_ids, #add_column, #prescreen_filters, #prescreen_member_ids').select2({
      placeholder: "All",
      allowClear: true
    });

    $('#from-filter, #to-filter').change(function(){
      if($(this).val() != ''){
        $(this).removeClass('error')
        $(this).next().remove()
      }
    });

    $('#save_filter').click(function(){
      var form = $('#daily_reports_form')
      var data = JSON.stringify(form.serialize())
      $('#save_filter_filters').val(data)
    });
    $('.btn-close').click()
    $('.main-section').scrollTop(0);
    $("#save_filter").click(function(){
      $("#myModal").modal('show');
    });
    dateWiseSpan()
    $('#loading_data').hide()

    $(document).mouseup(function(e) {
      if(e.target.id == 'advance-feature-btn')
        return true;

      var container = $('.advance-feature-list');
      if (!container.is(e.target) && container.has(e.target).length === 0){
        container.hide();
      }
    });

    $('.advance-feature-item input[type="checkbox"]').each(function(index, item){
      if(item.checked){
        $('.' + item.id).parent().parent().show()
     }else{
        $('.' + item.id).parent().parent().hide()
        $('.' + item.id).val('')
      }
    })

    $(document).mouseup(function(e) {
      if(e.target.id == 'add_column_btn' || e.target.id == 'select_state_column'){
        return true;
      }
      var container = $('.add_column_list');
      if (!container.is(e.target) && container.has(e.target).length === 0){
        container.hide();
      }
    });

    selectedColumn()
    if ($('#export-email-report').length == 1) {
      $('.add_column_options').show();
    }

    $('#more_option').change(function(){
      $('#more-filter-option input, #more-filter-option select').prop( "disabled", !this.checked );
    })
  }

  setSavedFilter(event){
    console.log("setSavedFilter_______________-------")
    let element = event.currentTarget
     $('#saved_filter_input').val(element.value)

      if(element.value == ''){
        $('#filter_id').val('')
        $('#save_filter_name').val('')
      }else{
        $('#filter_id').val(element.value)
        var filter_name = $(element).find('option[value="' + element.value + '"]').text()
        $('#save_filter_name').val(filter_name)
      }
      $("#saved_filter_btn").click();
  }

  moreCollapse(event){
      let element = event.currentTarget
      if(element.checked){
        $('.' + element.id).parent().parent().show()
      }else{
        $('.' + element.id).parent().parent().hide()
        if($('.' + element.id).find('option').length > 0){
          $('.' + element.id).select2('destroy').find('option').prop('selected', '').end().select2();
        }
        $('.' + element.id).val('')
      }
    }

  toggleColumn(){
    $('.add_column_list').toggle();
  }

  addedColumn(event){
    let element = event.currentTarget

    let counts = 0;

    addColumnItemInReport(element)

    let add_column = []
    $('.add_column_item input:checkbox').each(function(index, item){
      let message  = ''
      if(this.checked){
        add_column.push(item.id)
        counts  = $('.add_column_item input:checked').length
        message = counts < 1 ? 'Add column' : counts + " columns added"
        $('#add_column_btn').text(message)
      }else{
        message = counts < 1 ? 'Add column' : counts + " columns added"
        $('#add_column_btn').text(message)
      }

    })
    $('#email_fields_name').val(add_column)
    $('#field_name').val(add_column)

    $('#btn_submit').click(function(){
      $('.add_column_item input:checkbox').each(function(index, item){
        if(this.checked){
          add_column.push(item.id)
        }
      })
      var field_name = $('#field_name').val(add_column)
      var email_field_name = $('#email_fields_name').val(add_column)
      $('#daily_report_table').data('field_name', field_name )
      $('#daily_report_table').data('email_fields_name', email_field_name)
    });
  }

  submitForm(){
    console.log('detail_report')
    if($('#daily_reports_form').valid())
      $('#loading_data').show()
  }

  showReturns(event){
    let element  = event.currentTarget
    if (element.checked) {
      $(".return_date_radio_section").show()
    }else {
      $(".return_date_radio_section").hide()
    }
  }

  setMinMaxForDailyReport(){
    let minLoanAmount = $('#min_loan_amount_range_id')
    let maxLoanAmount = $('#max_loan_amount_range_id')
    if( maxLoanAmount.val().length > 0 )
    minLoanAmount.attr('max', maxLoanAmount.val())
    if( minLoanAmount.val().length > 0 )
    maxLoanAmount.attr('min', minLoanAmount.val())

    let min_apr_range =  $('#apr_range_form_id')
    let max_apr_range =  $('#apr_range_to_id')

    if( max_apr_range.val().length > 0 )
    min_apr_range.attr('max', max_apr_range.val())
    if( min_apr_range.val().length > 0 )
    max_apr_range.attr('min', min_apr_range.val())
  }

  clickSaveMapping(){
    $("#save_filter").click()
  }
}

window.dateWiseSpan = function() {
  $('.date_wise_filter').click(function(){
    let radioButtonValue = $(this).find('input:checked').val();

    if(radioButtonValue == 'funded_date_wise'){
      $('.date_wise_filter_text span').text('Funded');
    }else if ( radioButtonValue == 'return_date_wise'){
      $('.date_wise_filter_text span').text('Return');
    }else{
      $('.date_wise_filter_text span').text('App');
    }
  })
}

window.selectedColumn = function() {
  $('.add_column_item input:checkbox').each(function(index, item){
    var column_hide_index = $('.app_table th').filter('.' + $(item).attr("name")).index();
    if(item.checked){
      $('.app_table').find('tr :nth-child(' + (column_hide_index + 1) + ')').show();
    }else{
      $('.app_table').find('tr :nth-child(' + (column_hide_index + 1) + ')').hide();
    }
  })
}

window.addColumnItemInReport = function(element){
  var column_hide_index = $('.app_table th').filter('.' + $(element).attr("name")).index();
  if(element.checked){
    $('.app_table').find('tr :nth-child(' + (column_hide_index + 1) + ')').show();
  }else{
    $('.app_table').find('tr :nth-child(' + (column_hide_index + 1) + ')').hide();
  }
}

window.setSelect2 = function(){
  $('span.select2.select2-container.select2-container--default').remove()
  $('#member_ids, #lender_ids, #status_ids, #loan_purpose, #account_owner_ids, #state_ids, #promotion_ids, #add_column').select2({
    placeholder: "All"
  });
}